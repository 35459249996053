import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";
import { Visibility } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "25px",
  },
  headerLogoDiv: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerLogo: {
    height: "45px",
    width: "auto",
  },
  title: {
    // ...theme.typography.rockwell,
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    fontWeight: 700,
    margin: theme.spacing(0.4),
    color: theme.palette.common.headerFontColor,
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // padding: "5px",
    borderRadius: "10px",
    backgroundColor: theme?.palette?.card?.cardColor,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginBottom: "30px",
  },
  hideContainer: {
    width: "100%",
    display: "none",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "10px",
    backgroundColor: theme?.palette?.card?.cardColor,
    Visibility: "hidden",
  },
  timeSelectDiv: {
    padding: "10px",
    borderRadius: theme?.palette?.card?.cardBorderRadius || "10px",
    backgroundColor: theme?.palette?.card?.cardShadeColor,
  },
  radioBtn: {
    color: theme?.palette?.card?.cardShadeFontColor,
  },
  radioLabel: {
    color: theme?.palette?.card?.cardShadeFontColor,
  },
  sheduledContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "10px",
    backgroundColor: theme?.palette?.card?.cardColor,
  },
  btnNormal: {
    userSelect: "none",
    flexGrow: 1,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
    // border: theme?.palette?.button?.buttonBorder
    //   ? "1px solid black"
    //   : "1px solid black",
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    padding: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: "pointer",
    color: theme?.palette?.card?.cardFontColor || theme.palette.common.white,
    // backgroundColor:
    //   theme?.palette?.button?.buttonColor || theme.palette.common.white,
    "&:hover": {
      opacity: 0.8,
    },
    "&.selected": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  item: {
    userSelect: "none",
    flexGrow: 1,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
    border: theme?.palette?.button?.buttonBorder
      ? "1px solid black"
      : "1px solid black",
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    padding: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: "pointer",
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.common.white
    ),
    backgroundColor:
      theme?.palette?.button?.buttonColor || theme.palette.common.white,
    "&:hover": {
      opacity: 0.8,
    },
    "&.selected": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  itemText: {
    fontSize: calculateFontSize(15, theme.palette.common.fontVariation),
    fontWeight: 700,
    // color: theme.palette.common.normalTitle,
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.common.white
    ),
  },
  normalText: {
    fontSize: calculateFontSize(15, theme.palette.common.fontVariation),
    fontWeight: 700,
    // color: theme.palette.common.normalTitle,
    color: theme?.palette?.card?.cardFontColor || theme.palette.common.white,
  },
  infoDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  infoIcon: {
    marginRight: "5px",
    color: theme?.palette?.card?.cardShadeFontColor,
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
  },
  infoText: {
    color: theme?.palette?.card?.cardShadeFontColor,
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
  },
  itemDiv: {
    display: "flex",
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
  },
  itemIcon: {
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.common.white
    ),
  },
  normalIcon: {
    color: theme?.palette?.card?.cardFontColor || theme.palette.common.white,
  },
  itemDisabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  modalTitleSection: {
    marginBottom: theme.spacing(2.5),
    display: "flex",
  },
  modalTitle: {
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    fontWeight: 700,
    color: theme?.palette?.common?.themeFontColor,
  },
  dateTimeSelection: {
    width: "50%",
    marginBottom: "10px",
    backgroundColor: theme?.palette?.card?.cardColor,

    // color: theme.palette.common.normalTitle,
    // color: theme?.palette?.card?.cardFontColor,

    padding: "0 1% !important",
    "& :first-of-type": {
      marginLeft: "0",
      color: theme?.palette?.card?.cardFontColor,
      // color: theme.palette.common.normalTitle,
    },
    "& fieldset": {
      border: "none !important",
    },
  },
  btnDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  continueBtn: {
    height: "50px",
    width: "100%",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    backgroundColor: theme?.palette?.button?.buttonColor || "#000000",
    // border: `1px solid ${theme.palette.primary.main}`,
    color: `${setFontColorOnBg(
      theme?.palette?.button?.buttonColor || "#000000"
    )} !important`, // color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    marginTop: "20px",
    "&:hover": {
      color: "#fff",
      pointer: "cursor",
      borderRadius: "10px",
    },
  },
}));

export default useStyles;
