import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
// Material
import Typography from "@material-ui/core/Typography";
// Custom Hooks
import useDynamicHotelData from "../../../CustomHooks/useDynamicHotelData";
// Styles
import useStyles from "./SelectOrderType.style.js";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DeliveryTime from "../DeliveryTime/DeliveryTime.js";
import DateSlotPicker from "../../Checkout/DateSlotPicker/index.js";
import { useSelector } from "react-redux";
import { ORDER_TYPES } from "../../../utils/Constants.js";
import { generateDateSlots } from "../../../utils/utils.js";
import TimePicker from "../../Checkout/TimePicker/index.js";
import moment from "moment";
import { Info } from "@material-ui/icons";

const SelectOrderType = ({
  value = null,
  onSelect = () => {},
  formValues,
  handleClose,
  onDateAndTimeSelect,
}) => {
  const classes = useStyles();
  const { hotel } = useSelector((state) => state.hotel);
  const [selectedOderType, setOrderType] = useState(value);
  const [dateSlots, setDateSlots] = useState([]);
  const [dateSlotsError, setDateSlotsError] = useState(false);
  const [date, setDate] = useState(null);
  const [typeOfOrder, setTypeOfOrder] = useState("SCHEDULED");
  const [time, setTime] = useState(null);
  const { theme } = useSelector((state) => state?.theme);
  const [orderTimeType, setOrderTimeType] = useState(formValues?.deliveryType);

  const {
    getOrderTypes,
    getDeliveryIntervalSlots,
    getTodayDeliveryIntervalSlots,
    isAsapAvailable,
    isMultipleOrderType,
    getAsapTime,
  } = useDynamicHotelData();

  const isAsapOrderAvailable = useMemo(() => {
    return {};
  });

  // console.log("formValues", formValues);

  useEffect(() => {
    if (!formValues?.orderType && getOrderTypes?.length > 0) {
      onOrderTypeSelect("orderType", getOrderTypes[0].type);
    }
    if (!orderTimeType) {
      if (isAsapAvailable) {
        setOrderTimeType("ASAP");
        handleOrderTime("ASAP");
      } else {
        setOrderTimeType("SCHEDULED");
        handleOrderTime("SCHEDULED");
      }
    }
  }, [
    formValues?.orderType,
    getOrderTypes,
    formValues?.deliveryType,
    isAsapAvailable,
  ]);

  const generateDatePickerSlots = () => {
    let orderTypeValue = value;
    if (value === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING) {
      orderTypeValue = ORDER_TYPES.OPENING;
    }
    const dates = generateDateSlots(hotel, orderTypeValue) || [];
    setDateSlots(dates);
    if (!dates || !dates?.length) {
      setDateSlotsError(true);
    }
  };

  useEffect(() => {
    const timeString = `${date}${time ? ` ${time}` : ""}`;
    const dTime = moment(moment(timeString)).toISOString();
    onDateAndTimeSelect(typeOfOrder || "SCHEDULED", dTime);
  }, [date, time, typeOfOrder]);

  useEffect(() => {
    if (hotel || formValues?.orderType || value) {
      generateDatePickerSlots();
    }
  }, [hotel, formValues?.orderType, value]);

  const onOrderTypeSelect = (fieldName, type) => {
    setOrderType(type);
    onSelect(fieldName, type);
  };

  const onSelectDataAndTime = ({ value, typeOfOrder }, type) => {
    if (!value) {
      return;
    }

    if (type === "date") {
      setDate(value);
    }

    if (type === "time") {
      setTime(value);
    }

    if (typeOfOrder) {
      setTypeOfOrder(typeOfOrder);
    }
  };

  const getLabel = (arry) => {
    return arry[0]?.label;
  };

  const filterOrderDate = (array) => {
    // console.log("arrayyyyyyyyyy", array);
    return array?.find((arr) => arr?.label === "Today");
  };

  const filteredOrderTime = (array) => {
    return array?.find((arr) => arr?.typeOfOrder === "ASAP");
  };

  const handleOrderTime = (e) => {
    setOrderTimeType(e);
    if (e === "ASAP") {
      const filteredTime = filteredOrderTime(
        getTodayDeliveryIntervalSlots?.length > 0
          ? getTodayDeliveryIntervalSlots
          : getDeliveryIntervalSlots
      );
      // console.log("filteredddddd time", filteredTime);
      onSelectDataAndTime(filteredTime, "time");
    }
  };

  const getDeliveryType = useMemo(() => {
    return selectedOderType;
  }, [selectedOderType]);

  const getLastTime = (array) => {
    if (!array || array.length === 0) {
      return undefined; // Return undefined if array is empty or null
    }

    const lastElement = array[array.length - 1];

    return lastElement?.label; // Return the first key of the last object
  };

  // console.log("resulttttttttt", resukt);

  return (
    <>
      {/* <div className={classes.headerDiv}>
        {theme?.header_logo ? (
          <div className={classes.headerLogoDiv}>
            <img
              src={`data:image/png;base64, ${theme?.header_logo}`}
              alt="Header Logo"
              className={classes.headerLogo}
            />
          </div>
        ) : (
          <Typography
            className={clsx(classes.title)}
            variant="h1"
            display="block"
          >
            {hotel ? hotel.hotelName : ""}
          </Typography>
        )}
      </div> */}
      {isMultipleOrderType && (
        <div className={classes.modalTitleSection}>
          <Typography
            className={classes.modalTitle}
            variant="h1"
            align="center"
            paragraph
          >
            {"Select Order Type"}
          </Typography>
          <Divider />
        </div>
      )}
      {isMultipleOrderType && (
        <div id="checkout-orderTypeForm" className={clsx(classes.container)}>
          {getOrderTypes?.map((o, i) => (
            <div
              key={i}
              className={clsx(
                classes.btnNormal,
                o.type === selectedOderType && classes.item
                // o.active === false && classes.itemDisabled
              )}
              onClick={() => onOrderTypeSelect("orderType", o.type)}
            >
              <div className={classes.itemDiv}>
                <o.Icon
                  className={clsx(
                    o.type === selectedOderType
                      ? classes.itemIcon
                      : classes.normalIcon
                  )}
                />
              </div>
              <Typography
                // color="primary"
                className={clsx(
                  o.type === selectedOderType
                    ? classes.itemText
                    : classes.normalText
                )}
                variant="caption"
              >
                {o.title}
              </Typography>
            </div>
          ))}
        </div>
      )}
      <div className={classes.modalTitleSection} style={{ marginTop: "16px" }}>
        <Typography
          className={classes.modalTitle}
          variant="h1"
          align="center"
          paragraph
        >
          {"Order Time"}
        </Typography>
        <Divider />
      </div>
      <div className={classes.timeSelectDiv}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="ASAP"
              className={classes.radioLabel}
              control={<Radio className={classes.radioBtn} />}
              label={
                !isAsapAvailable
                  ? "ASAP : Unavailable"
                  : `ASAP : order in ${getLabel(
                      getTodayDeliveryIntervalSlots.length > 0
                        ? getTodayDeliveryIntervalSlots
                        : getDeliveryIntervalSlots
                    )} `
              }
              checked={orderTimeType === "ASAP"}
              onChange={(e) => handleOrderTime(e.target.value)}
              disabled={!isAsapAvailable}
            />
            <FormControlLabel
              value="SCHEDULED"
              className={classes.radioLabel}
              control={<Radio className={classes.radioBtn} />}
              label="Shedulued for later"
              checked={orderTimeType === "SCHEDULED"}
              onChange={(e) => handleOrderTime(e.target.value)}
            />
          </RadioGroup>
        </FormControl>
        <div
          id="checkout-orderTypeForm"
          className={clsx(
            orderTimeType === "SCHEDULED"
              ? classes.sheduledContainer
              : classes.hideContainer
          )}
        >
          <DateSlotPicker
            slots={dateSlots}
            className={`w-100 ${classes.dateTimeSelection}`}
            onChange={onSelectDataAndTime}
          />
          <TimePicker
            slots={
              moment(formValues.deliveryTime).isSame(moment(), "date") &&
              // filteredTimeSlots(
              getTodayDeliveryIntervalSlots.length > 0
                ? getTodayDeliveryIntervalSlots
                : getDeliveryIntervalSlots
              // )
            }
            shouldFilter={true}
            date={date}
            orderTimeType={orderTimeType}
            className={`w-100 ${classes.dateTimeSelection}`}
            onChange={onSelectDataAndTime}
          />
        </div>

        {orderTimeType === "SCHEDULED" &&
          getLastTime(
            getTodayDeliveryIntervalSlots.length > 0
              ? getTodayDeliveryIntervalSlots
              : getDeliveryIntervalSlots
          ) &&
          getDeliveryType && (
            <div className={classes.infoDiv}>
              <Info className={classes.infoIcon} />
              <Typography className={classes.infoText}>
                <span style={{ fontWeight: "800" }}>{getDeliveryType}</span>{" "}
                available until{" "}
                <span style={{ fontWeight: "800" }}>
                  {getLastTime(
                    getTodayDeliveryIntervalSlots.length > 0
                      ? getTodayDeliveryIntervalSlots
                      : getDeliveryIntervalSlots
                  )}
                </span>
              </Typography>
            </div>
          )}
      </div>

      {/* {orderTimeType === "shedulued" && ( */}
      {/* )} */}
      <div className={classes.btnDiv}>
        <Button
          disabled={!formValues?.orderType}
          className={classes.continueBtn}
          onClick={handleClose}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default SelectOrderType;
