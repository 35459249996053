import React, { useEffect, useState } from "react";
import { firebaseAuth, provider, setUpAppleSignIn } from "../../firebase";
import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../store/User/UserAction";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import { useStyles, useStylesMobile } from "./SocialMediaLogin.style";
import { Close } from "@material-ui/icons";
import CheckoutLoader from "../CheckoutLoader/CheckoutLoader";
import googlePng from "../../assets/images/googlePng.png";
import appleLogoPng from "../../assets/images/appleLogoPng.png";

const SocialMediaLogin = ({
  setOpen,
  changeFormType,
  loginFormValues,
  setLoginFormValues,
}) => {
  const classes = useStyles();
  const classesMobile = useStylesMobile();
  const [mailValue, setMailValue] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const userCheckHandler = async (userData, uid, data) => {
    if (userData) {
      setOpen(false);
      dispatchSnackbarSuccess("Login Successfully");
    } else {
      setLoginFormValues({
        ...loginFormValues,
        customer_name_uid: uid,
        customer_name: data?.user?.displayName || "",
        customer_email: data?.user?.email || "",
      });
      changeFormType("signup");
    }
  };

  const handleSocialSigin = async (data, uid) => {
    try {
      setLoading(true);
      dispatch(loginUser(uid));
      setTimeout(() => {
        setLoading(false);
        userCheckHandler(user, uid, data);
        if (user) {
          window.location.reload();
        }
      }, 3000);
      // userCheckHandler(user, uid, data);
    } catch (error) {
      console.log("Error in social sign-in:", error);
    }
  };

  const handleGoogleSignin = async () => {
    try {
      signInWithPopup(firebaseAuth, provider).then((data) => {
        setMailValue(data?.user?.email);
        localStorage.setItem("email", data?.user?.email);
        // dispatch(getUser(data?.user));

        handleSocialSigin(data, data?.user?.uid);
      });
    } catch (error) {
      console.log("errorrrrrrr in google", error);
      dispatchSnackbarError(error?.toString() || "Something wend wrong!");
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAppleSignIn = async () => {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    // console.log("providerrrrrrrrr", provider);
    // console.log("firebase authhhhhhhh", firebaseAuth);
    try {
      // Sign in with popup
      const result = await signInWithPopup(firebaseAuth, provider);
      // console.log("resulttttttttt", result);
      // The signed-in user info.
      const appleUser = result.user;

      // Apple credential
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;
      // IdP data available using getAdditionalUserInfo(result)
      // ...
      // console.log("appleeeeeeeeeeeeee", appleUser);
      handleSocialSigin(appleUser, appleUser?.uid);
    } catch (error) {
      // Handle errors
      console.error("Apple Sign-In failed:", error);
    }
  };

  // useEffect(() => {
  //   setMailValue(localStorage.getItem("email"));
  //   if (mailValue) {
  //     history.push("/home");
  //   }
  // });

  return (
    <div className={isMobile ? classesMobile.root : classes.root}>
      {loading ? (
        <CheckoutLoader />
      ) : (
        <>
          <div className={classes.titleContainer}>
            <Typography variant="h4" className={classes.title}>
              Login
            </Typography>
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              className={
                isMobile ? classesMobile.googleButton : classes.googleButton
              }
              onClick={handleGoogleSignin}
            >
              <img
                src={googlePng}
                alt="Google Logo"
                className={classes.logoImage}
              />
              Login with GMAIL
            </Button>
            <Button
              variant="contained"
              className={
                isMobile ? classesMobile.appleButton : classes.appleButton
              }
              onClick={handleAppleSignIn}
            >
              <img
                src={appleLogoPng}
                alt="Apple Logo"
                className={classes.appleLogoImg}
              />
              Login with APPLE
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default SocialMediaLogin;
