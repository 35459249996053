import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  filterBarContainer: {
    height: "100%",
  },
  searchBar: {
    marginLeft: "1rem", // Adjust as needed for spacing
    flex: 1, // Ensure it takes up the remaining space
  },
  inputRoot: {
    color: theme.palette.common.themeFontColor,
  },
  searchDiv: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common?.background,
    marginLeft: theme.spacing(1),
    width: "100%",
    marginBottom: "1px",
    marginRight: "10px",
  },
  searchMobileDiv: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common?.background,
    marginLeft: theme.spacing(1),
    width: "100%",
    marginBottom: "5px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  bgColor: {
    backgroundColor:
      theme.palette.common?.headerColor || theme.palette.common.white,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    // padding: theme.spacing(0, 1.6),
    // [theme.breakpoints.up('sm')]: {
    //     padding: theme.spacing(0, 2),
    // },
    // [theme.breakpoints.up('md')]: {
    //     padding: theme.spacing(0, 12),
    // },
  },
  mobileBtn: {
    height: "33px",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    textTransform: "none",
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  btn: {
    height: "33px",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    backgroundColor: theme?.palette?.button?.buttonColor || "transparent",
    border: theme?.palette?.button?.buttonBorder
      ? "1px solid black"
      : `1px solid ${theme.palette.primary.main}`,
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#e0e0e0"),
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    // color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    textTransform: "none",
    "&:hover": {
      color: "black",
    },
  },
  disabledItem: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  sectionDesktop: {
    height: "100%",
    alignItems: "center",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  sectionMobile: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawer: {
    width: 295,
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 592,
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.common.background,
    width: 295,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 592,
    },
  },
  filterDrawerContainer: {
    padding: theme.spacing(2.4, 1.6, 0, 1.6),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.4, 4, 0, 4),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2.4, 12, 0, 8),
    },
  },
  filterDrawerTitle: {
    fontWeight: 700,
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    marginBottom: theme.spacing(2.4),
  },
  filterListContainer: {
    // padding: theme.spacing(2.4, 12, 0, 8),
  },
  nextAvailable: {
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#ffffff"),
    margin: theme.spacing(0.4, 0.4, 0.4, 0.7),
    backgroundColor: theme?.palette?.button?.buttonColor || "transparent",
    fontWeight: 500,
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    border: theme?.palette?.button?.buttonBorder
      ? "1px solid black"
      : "1px solid black",
    padding: theme.spacing(0.7, 1.6),
  },

  catMobileBtn: {
    height: "33px",
    fontSize: calculateFontSize(10, theme.palette.common.fontVariation),
    backgroundColor: "transparent",
    // border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.subHeaderFontColor,
    // color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    display: "flex",
    borderRadius: "0",
    boxShadow: "none",
    marginRight: "-20px",
    marginTop: "3px",
    paddingRight: "4px",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    "&:hover": {
      color: theme.palette.common.white,
      pointer: "cursor",
      borderRadius: "10px",
    },
  },
  searchBtn: {
    height: "33px",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    backgroundColor: "transparent",
    color: theme.palette.common.subHeaderFontColor,
    // color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    // border: theme?.palette?.button?.buttonBorder
    //   ? "1px solid black"
    //   : "1px solid white",
    borderRadius:
      theme?.palette?.button?.borderRadius || theme?.shape?.borderRadius,
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    "&:hover": {
      color: theme.palette.common.white,
      pointer: "cursor",
      borderRadius: "10px",
    },
  },
  catBtn: {
    height: "33px",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    backgroundColor: "transparent",
    // border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.subHeaderFontColor,
    // color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    borderRadius: "0",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    "&:hover": {
      color: theme.palette.common.white,
      pointer: "cursor",
      borderRadius: "10px",
    },
  },
  categoryDiv: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  leftDiv: {
    height: "100%",
    width: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightMobileDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  leftExpandedDiv: {
    display: "flex",
    width: "80%",
  },
  leftMobileDiv: {
    display: "flex",
    width: "94%",
  },
  leftDiv: {
    display: "flex",
  },
  scrollbarScrollMobileDiv: {
    overflowX: "auto",
    display: "flex",
    fontFamily: theme?.palette?.common?.fontFamily,
    whiteSpace: "nowrap",
    width: "72%",
    boxShadow: "none",
    marginLeft: "10px",
    backgroundColor: theme.palette.common.background,
    "&::-webkit-scrollbar": {
      width: "0", // Hide the horizontal scrollbar
      height: "0", // Hide the vertical scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "lightgray", // Customize scrollbar thumb color
    },
  },
  scrollbarMobileDiv: {
    overflowX: "auto",
    display: "flex",
    fontFamily: theme?.palette?.common?.fontFamily,
    whiteSpace: "nowrap",
    maxWidth: "0%",
    boxShadow: "none",
    marginLeft: "22px",
    backgroundColor: theme.palette.common.background,
    "&::-webkit-scrollbar": {
      width: "0", // Hide the horizontal scrollbar
      height: "0", // Hide the vertical scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "lightgray", // Customize scrollbar thumb color
    },
  },
  scrollableDiv: {
    overflowX: "auto",
    display: "flex",
    whiteSpace: "nowrap",
    boxShadow: "none",
    maxWidth: "98%",
    fontFamily: theme?.palette?.common?.fontFamily,
    backgroundColor: theme.palette.common.background,

    // scrollPaddingBottom: "10px",
    "&::-webkit-scrollbar": {
      width: "0", // Hide the horizontal scrollbar
      height: "0", // Hide the vertical scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "lightgray", // Customize scrollbar thumb color
    },
  },
  item: {
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    padding: "8px", // Add padding to create spacing
    width: "auto",
    height: "100%",
    color: theme?.palette?.common?.themeFontColor,
    // paddingBottom: "0",
    // fontFamily: theme?.palette?.common?.fontFamily || "Poppins-Regular",
    // fontFamily: "sofia-Regular",
    "&:hover": {
      fontWeight: 600,
      cursor: "pointer",
    },
  },
  boldText: {
    fontWeight: 600,
    textDecoration: "underline",
    color: theme?.palette?.button?.buttonColor,
  },
}));

export default useStyles;
