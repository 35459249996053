import * as types from "./LocationTypes";

const INITIAL_STATE = {
  isLocationProcessed: false,
  latitude: null,
  longitude: null,
  distance: null,
  duration: null,
  currentDeliveryZone: null,
  selectedAddress: null,
  userCountry: null,
  userCountryCode: null,
  isLocationInvalid: false,
  differ: null,
  zone: null,
  validAddress: null,
};

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISTANCE_DURATION_REQUEST:
      return {
        ...state,
        isLocationProcessed: false,
      };
    case types.DISTANCE_DURATION_RECEIVE:
      const { distance, duration, deliveryzone, ...payload } = action.payload;
      return {
        ...state,
        ...payload,
        isLocationProcessed: true,
        distance,
        duration,
        currentDeliveryZone: deliveryzone,
      };
    // const { distance, duration, deliveryzones, ...payload } = action.payload;
    // const formattedDistance = Number((distance.value / 1000).toFixed());
    // let inValidDistance = false;
    // const zone = deliveryzones.find((z) => z.zone_area >= formattedDistance);
    // if (!zone) {
    //   inValidDistance = true;
    // }
    // return {
    //   ...state,
    //   ...payload,
    //   isLocationProcessed: true,
    //   distance: formattedDistance,
    //   duration: duration.text,
    //   inValidDistance,
    // };
    case types.UPDATE_DELIVERY_ZONE_LOCATION:
      const { deliveryZone } = action.payload;
      return {
        ...state,
        currentDeliveryZone: deliveryZone,
      };
    case types.CLEAR_LOCATION:
      return {
        ...INITIAL_STATE,
      };
    case types.SET_IS_LOCATION_INVALID:
      return {
        ...state,
        isLocationInvalid: action.payload,
      };
    case types.GET_VALID_LOCATION_RECEIVE:
      return {
        ...state,
        validAddress: action.payload,
      };
    case types.SET_DIFF_TO_FREE_DELIVERY:
      return {
        ...state,
        differ: action.payload,
      };
    case types.SET_ZONE:
      return {
        ...state,
        zone: action.payload,
      };
    case types.SET_USER_COUNTRY:
      return {
        ...state,
        userCountry: action.payload,
      };
    case types.GET_USER_COUNTRY_SUCCESS:
      return {
        ...state,
        userCountryCode: action.payload,
      };
    case types.CLEAR_DURATION:
      return {
        ...state,
        duration: null,
      };
    case types.SET_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
