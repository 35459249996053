import { axiosInstance } from "../../network/apis";
import { API } from "../../utils/Constants";

export const getCustomerOrders = async (payload) => {
  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.CUSTOMER_ORDERS}`,
    {
      uid: payload?.payload?.uid,
      online_key: payload?.payload?.online_key,
    },
    { handlerEnabled: false }
  );

  if (data.status === false) {
    throw new Error(data.message);
  }
  return data;
};

export const getCustomerWalletOrders = async (payload) => {
  // console.log("payloadddddddddddddd", payload);
  try {
    const { data } = await axiosInstance.get(
      `${API.ENDPOINTS.GET_WALLET_HISTORY}`,
      {
        params: {
          customer_id: payload,
        },
        handlerEnabled: false,
      }
    );
    // console.log("dataaaaaaaaaa", data);

    if (data.status === false) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    console.error("Error fetching customer wallet orders:", error);
    throw error;
  }
};

export const getCustomerOrderByPageNo = async (payload) => {
  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.CUSTOMER_ORDERS}?page=${payload?.payload?.pageNo}`,
    {
      uid: payload?.payload?.uid,
      online_key: payload?.payload?.online_key,
    },
    { handlerEnabled: false }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return data;
};

export const triggerEmailVerificationApi = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `${API.ENDPOINTS.TRIGGER_EMAIL_VERIFICATION}`,
      {
        uid: payload?.payload?.uid,
        email: payload?.payload?.email,
      },
      { handlerEnabled: false }
    );
    if (response.status !== 200) {
      throw new Error(
        response.data.message || "Error triggering email verification"
      );
    }
    return { data: response.data, status: response.status };
  } catch (error) {
    console.error("API error:", error.message);
    throw error; // Rethrowing the error so the saga can catch it
  }
};

export const verifyEmailOtpUser = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `${API.ENDPOINTS.VERIFY_EMAIL_OTP}`,
      {
        uid: payload?.payload?.uid,
        email: payload?.payload?.email,
        code: payload?.payload?.code,
      },
      { handlerEnabled: false }
    );
    if (response.status !== 200) {
      throw new Error(
        response.data.message || "Error triggering email verification"
      );
    }
    return response;
  } catch (error) {
    console.error("API error:", error.message);
    throw error; // Rethrowing the error so the saga can catch it
  }
};
